import { service } from "./request";

import { AxiosPromise } from "axios";

// import { config } from "@/config/axios/config";

// const { default_headers } = config

declare type AxiosMethod = "get" | "post" | "delete" | "put";

declare type AxiosResponseType =
  | "arraybuffer"
  | "blob"
  | "document"
  | "json"
  | "text"
  | "stream";

declare interface AxiosConfig {
  params?: any;
  data?: any;
  url?: string;
  method?: AxiosMethod;
  headersType?: string;
  responseType?: AxiosResponseType;
}

const request = (option: any) => {
  const { url, method, params, data, headersType, responseType } = option;

  return service({
    url: url,
    method,
    params,
    data,
    responseType: responseType,
    headers: {
      "Content-Type": headersType,
    },
  });
};

function getFn<T = any>(option: AxiosConfig): AxiosPromise<T> {
  return request({ method: "get", ...option });
}

function postFn<T = any>(option: AxiosConfig): AxiosPromise<T> {
  return request({ method: "post", ...option });
}

function deleteFn<T = any>(option: AxiosConfig): AxiosPromise<T> {
  return request({ method: "delete", ...option });
}

function putFn<T = any>(option: AxiosConfig): AxiosPromise<T> {
  return request({ method: "put", ...option });
}

export const useAxios = () => {
  return {
    get: getFn,
    post: postFn,
    delete: deleteFn,
    put: putFn,
  };
};
