<template>
  <el-main>
    <router-view />
  </el-main>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppMain",
  methods: {},
});
</script>

<style lang="less" scoped>
.el-main {
  min-height: var(--app-content-height);
  padding: var(--el-main-padding);
  background-color: var(--app-contnet-bg-color);
}

// 饿了么
:deep(.avatar-uploader) {
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
  }

  .el-upload:hover {
    border-color: var(--el-color-primary);
  }
}

:deep(.el-icon.avatar-uploader-icon) {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
