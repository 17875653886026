import { createStore } from "vuex";

export default createStore({
  state: {
    /**
     * 是否对接ERP
     */
    isDorkErp: 0,
  },
  getters: {
    setShopDockErpStatus(state) {
      if (!state.isDorkErp) {
        /**
         * 读取token携带数据
         */
        const tokenData: any = localStorage.getItem("token_data");

        /**
         * 解析token数据
         */
        const tokenInfo = JSON.parse(tokenData);

        if (tokenInfo) {
          if (tokenInfo.erp_no) {
            state.isDorkErp = tokenInfo.erp_no;
          }
        }
      }

      return state.isDorkErp;
    },
  },
  mutations: {
    /**
     * 设置连锁对接ERP状态
     */
    setShopDockErpStatus(state, erpNo) {
      state.isDorkErp = erpNo;
    },
  },
  actions: {},
  modules: {},
});
