import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "button-group" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.option.includes('cancel'))
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          type: "default",
          class: "btn btn-cancel",
          style: _normalizeStyle({ width: _ctx.cancelButtonWidth }),
          disabled: _ctx.disabled || _ctx.cancelDisabled,
          loading: _ctx.loading || _ctx.cancelLoading,
          onClick: _ctx.handleClickCancelButton
        }, {
          default: _withCtx(() => [
            (_ctx.cancelImgIcon)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.cancelImgIcon,
                  class: "btn-icon btn-icon-cancel",
                  alt: "icon"
                }, null, 8, _hoisted_2))
              : _createCommentVNode("", true),
            _createElementVNode("span", null, _toDisplayString(_ctx.cancelText), 1)
          ]),
          _: 1
        }, 8, ["style", "disabled", "loading", "onClick"]))
      : _createCommentVNode("", true),
    (_ctx.option.includes('submit'))
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          type: "default",
          class: "btn btn-submit",
          style: _normalizeStyle({ width: _ctx.submitButtonWidth }),
          disabled: _ctx.disabled || _ctx.submitDisabled,
          loading: _ctx.loading || _ctx.submitLoading,
          onClick: _ctx.handleClickSubmitButton
        }, {
          default: _withCtx(() => [
            (_ctx.submitImgIcon)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.submitImgIcon,
                  class: "btn-icon btn-icon-submit",
                  alt: "icon"
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            _createElementVNode("span", null, _toDisplayString(_ctx.submitText), 1)
          ]),
          _: 1
        }, 8, ["style", "disabled", "loading", "onClick"]))
      : _createCommentVNode("", true)
  ]))
}