<template>
  <el-footer>
    <p style="margin: 0">
      付生富加单营销系统 @{{ getNowYear() }} 北京亿达神州医药科技有限公司
      版权所有
    </p>
  </el-footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppFooter",
  methods: {
    getNowYear() {
      if (new Date().getFullYear() == 2022) {
        return new Date().getFullYear();
      } else {
        return "2022-" + new Date().getFullYear();
      }
    },
  },
});
</script>

<style lang="less" scoped>
.el-footer {
  height: var(--app-footer-height);
  line-height: var(--app-footer-height);
  color: var(--app-footer-color);
}
</style>
