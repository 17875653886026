<template>
  <div>
    <div class="tags-view">
      <!-- <el-button round @click="goBack" class="tags-btn"
        ><i class="el-icon-arrow-left"></i>返回</el-button
      > -->

      <div
        ref="tag"
        class="tags-view-item"
        v-for="pathItem in historyPathTagData"
        :class="isActive(pathItem) ? 'active' : ''"
        :key="pathItem.son_path"
      >
        <span @click="goMenus(pathItem)">{{ pathItem.menus_name }}</span>

        <el-icon
          size="16"
          @click="closeSelectedTag(pathItem)"
          style="margin-left: 7px"
        >
          <CircleClose />
        </el-icon>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      historyPathTagData: [] as any[],
    };
  },
  props: {
    /**
     * 历史path
     */
    historyPathData: Array,
    default: () => [],
  },
  watch: {
    historyPathData(pathData: any) {
      this.$nextTick(() => {
        this.historyPathTagData = pathData;
      });
    },
  },
  methods: {
    /**
     * 返回
     */
    goBack() {
      this.$router.go(-1);
    },

    /**
     * 比较选中的与当前path是否一样
     */
    isActive(route: any) {
      return route.son_path === this.$route.path;
    },

    /**
     * 删除历史path标签
     */
    closeSelectedTag(item: any) {
      let historyPath = localStorage.getItem("history_path");

      if (historyPath) {
        /**
         * 解析成数组
         */
        let cacheHistoryPathData = JSON.parse(historyPath);

        /**
         * 判断path和点击的path是否一致，一致则删除
         */
        for (let index = 0; index < cacheHistoryPathData.length; index++) {
          if (cacheHistoryPathData[index]["son_path"] == item.son_path) {
            cacheHistoryPathData.splice(index, 1);
            break;
          }
        }

        /**
         * 存入新数据
         */
        if (cacheHistoryPathData) {
          this.historyPathTagData = cacheHistoryPathData;

          localStorage.setItem(
            "history_path",
            JSON.stringify(cacheHistoryPathData)
          );

          /**
           * 判断当前删除的是否是选中的，如果不是选中的则直接删掉
           */
          if (item.son_path == this.$route.path) {
            /**
             * 最后一个菜单
             */
            let lastPath =
              cacheHistoryPathData[cacheHistoryPathData.length - 1];

            this.goMenus(lastPath);
          }
        }
      }
    },

    /**
     * 跳转不同的父级菜单
     */
    goMenus(item: any) {
      if (item) {
        let param = {
          parent_path: item.parent_path,
          son_path: item.son_path,
          title: item.menus_name,
        };

        this.$emit("historyPathToMenus", param);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.tags-view {
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  overflow: hidden;
  overflow-x: auto;
  .tags-btn {
    border-radius: 10px;
    background: #f6f6f6;
    border: none;
  }

  .tags-view-item {
    display: flex !important;
    align-items: center;
    display: inline-block;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    color: #666666;
    padding: 1px 18px;
    font-size: 12px;
    margin-top: 4px;
    border-radius: 6px 6px 0 0 !important;
    background: url("@/assets/icon/tab.png") no-repeat 100% 100%;
    background-size: 100% 93%;
    white-space: nowrap !important;
    &:first-of-type {
      margin-left: 15px;
    }

    &:last-of-type {
      margin-right: 15px;
    }

    &.active {
      color: #fff;
      background: url("@/assets/icon/tab_index.png") no-repeat 96% 100%;
      background-size: 98% 90%;
      &::before {
        content: "";
        background: #fff;
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        position: relative;
        margin-right: 2px;
      }
    }
  }
}
</style>
