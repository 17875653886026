<template>
  <div class="app-container">
    <el-container>
      <!-- 头部布局 -->
      <AppHeader
        @getChindrenData="getChindrenData"
        :defaultMenus="defaultMenus"
      />

      <el-container>
        <!-- 侧边栏布局 -->
        <AppSidebar
          ref="AppSidebar"
          @getHistoryPath="getHistoryPath"
          :defaultSonMenus="defaultSonMenus"
        />

        <el-container id="main">
          <!-- 打开标签 -->
          <tags-view
            :historyPathData="historyPathData"
            @historyPathToMenus="historyPathToMenus"
          />

          <!-- 内容区域 -->
          <AppMain />

          <!-- 页面底部 -->
          <AppFooter />
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import {
  AppFooter,
  AppMain,
  AppHeader,
  AppSidebar,
  TagsView,
} from "./components";

export default defineComponent({
  name: "AppLayout",
  components: {
    AppFooter,
    AppMain,
    AppSidebar,
    AppHeader,
    TagsView,
  },
  data() {
    return {
      /**
       * 历史菜单路径数据
       */
      historyPathData: [] as any[],

      /**
       * 顶级菜单
       */
      defaultMenus: "",

      /**
       * 左侧菜单
       */
      defaultSonMenus: "",
    };
  },
  methods: {
    /**
     * 点击头部菜单获取当前items 给左侧数据返回
     */
    getChindrenData(menus: any, isHeader = 0) {
      if (menus) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let that: any = this;

        let path = "";
        let title = "";
        let isHight = "";

        if (isHeader != 1) {
          path = that.$route.fullPath;
          title = that.$route.meta.title;
          isHight = "2";
        } else {
          isHight = "1";
        }

        that.$refs.AppSidebar.childrenMenusData(menus, path, title, isHight);
      }
    },

    /**
     * 点击左侧菜单同步展示历史菜单数据
     */
    getHistoryPath(pathData: any) {
      this.historyPathData = pathData;
    },

    /**
     * 页面刷新加载后获取历史菜单path
     */
    getDefaultHistroyPath() {
      let historyPath = localStorage.getItem("history_path");

      if (historyPath) {
        this.historyPathData = JSON.parse(historyPath);
      }
    },

    /**
     * 历史路径跳转菜单
     */
    historyPathToMenus(params: any) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let that: any = this;

      let routers = localStorage.getItem("menus_data");

      // select_menus_index
      /**
       * 解析菜单数据
       */
      if (routers) {
        let menus = [];

        menus = JSON.parse(routers);

        menus.forEach(function (item: any) {
          if (item.path == params.parent_path) {
            that.defaultMenus = params.parent_path;

            /**
             * 给左侧菜单传值展示
             */
            that.$refs.AppSidebar.childrenMenusData(
              item,
              params.son_path,
              params.title,
              "1"
            );
          } else if (item.path == "/" && params.parent_path == "/home") {
            /**
             * 给左侧菜单传值展示
             */
            that.$refs.AppSidebar.childrenMenusData(
              item,
              params.son_path,
              params.title,
              "1"
            );

            that.defaultMenus = "/";
          }
        });
      }
    },
  },
  mounted() {
    /**
     * 获取历史菜单path
     */
    this.getDefaultHistroyPath();
  },
});
</script>

<style lang="scss">
.app-container {
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  .el-container:first-child {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    #main {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
