import { AppLayout } from "@/layout";

const ChainManagement = {
  component: AppLayout,
  path: "/chainManagement",
  // redirect: "/chainManagement/chainManage/chain",
  name: "chainManagement",
  meta: { title: "连锁管理", menus_type: 2 },
  children: [
    {
      name: "chainManage",
      path: "/chainManagement/chainManage",
      meta: {
        title: "连锁管理", // 商品管理
        menus_type: 2,
      },
      children: [
        {
          path: "/chainManagement/chainManage/chain",
          name: "chain",
          component: () => import("@/views/ChainManagement/ChainView.vue"),
          meta: { title: "连锁列表", menus_type: 2 },
        },
        {
          path: "/chainManagement/chainManage/editChain",
          name: "editChain",
          component: () => import("@/views/ChainManagement/EditView.vue"),
          meta: { title: "编辑页面", hidden: true, menus_type: 2 },
        },
        {
          path: "/chainManagement/chainManage/authority",
          name: "authority",
          component: () => import("@/views/ChainManagement/AuthorityView.vue"),
          meta: { title: "权限管理", menus_type: 2 },
        },
        {
          path: "/chainManagement/chainManage/staffManage",
          name: "staffManage",
          component: () =>
            import("@/views/ChainManagement/StaffManageView.vue"),
          meta: { title: "员工列表", menus_type: 2 },
        },
        {
          path: "/chainManagement/chainManage/storeManage",
          name: "storeManage",
          component: () =>
            import("@/views/ChainManagement/StoreManageView.vue"),
          meta: { title: "连锁门店", menus_type: 2 },
        },
      ],
    },
    {
      path: "/chainManagement/equipManagement",
      name: "equipManagement",
      meta: { title: "设备管理", menus_type: 2 },
      children: [
        {
          path: "/chainManagement/equipManagement/devices",
          name: "devices",
          component: () =>
            import("@/views/ChainManagement/EquipManagement/DevicesView.vue"),
          meta: { title: "设备列表", menus_type: 2 },
        },
        {
          path: "/chainManagement/equipManagement/activationCode",
          name: "activationCode",
          component: () =>
            import(
              "@/views/ChainManagement/EquipManagement/ActivationCodeView.vue"
            ),
          meta: { title: "序列号管理", menus_type: 2 },
        },
        {
          path: "/chainManagement/equipManagement/deviceType",
          name: "deviceType",
          component: () =>
            import(
              "@/views/ChainManagement/EquipManagement/DeviceTypeManageView.vue"
            ),
          meta: { title: "设备类型管理", menus_type: 2 },
        },
        {
          path: "/chainManagement/equipManagement/diseaseAdvert",
          name: "diseaseAdvert",
          component: () =>
            import(
              "@/views/ChainManagement/EquipManagement/DiseaseAdvertView.vue"
            ),
          meta: { title: "疾病营销广告", menus_type: 2 },
        },
        {
          path: "/chainManagement/equipManagement/editDiseaseAdvert",
          name: "editDiseaseAdvert",
          component: () =>
            import(
              "@/views/ChainManagement/EquipManagement/EditDiseaseAdvertView.vue"
            ),
          meta: { title: "新增/编辑营销广告", menus_type: 2, hidden: true },
        },
      ],
    },
  ],
};

export { ChainManagement };
