import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";

import md5 from "js-md5";
import { ElMessage } from "element-plus";
import router from "@/router";

declare type AxiosHeaders =
  | "application/json"
  | "application/x-www-form-urlencoded"
  | "multipart/form-data";

const config: {
  base_url: {
    dev: string;
    pro: string;
  };
  result_code: number | string;
  default_headers: AxiosHeaders;
  request_timeout: number;
} = {
  /**
   * api请求基础路径
   */
  base_url: {
    // 开发环境接口前缀
    dev: "http://admin.lmkshouyin.com:8100/v1",
    // 打包生产环境接口前缀
    pro: window.origin + "/v1",
  },

  /**
   * 接口成功返回状态码
   */
  result_code: 100,

  /**
   * 接口请求超时时间
   */
  request_timeout: 6000000,

  /**
   * 默认接口请求类型
   * 可选值：application/x-www-form-urlencoded multipart/form-data
   */
  default_headers: "application/json",
};

const { result_code, base_url } = config;

// const whiteList = ["/auth/login"];

let baseURL = base_url.pro;

if (process.env.NODE_ENV == "development") {
  baseURL = base_url.dev;
}

// 创建axios实例
const service: AxiosInstance = axios.create({
  baseURL: baseURL, // api 的 base_url
  timeout: config.request_timeout, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  (requestConfig: AxiosRequestConfig) => {
    const token: any = localStorage.getItem("token");

    if (!requestConfig.params) {
      requestConfig.params = {};
    }

    /**
     * 配置请求前置处理
     */
    // eslint-disable-next-line no-use-before-define
    const payload = getPayload(token);

    let postData = "";

    if (requestConfig.method === "post") {
      postData = JSON.stringify(requestConfig.data);
    }

    requestConfig.params["timestamp"] =
      Date.parse(new Date().toString()) / 1000;
    // eslint-disable-next-line no-use-before-define
    requestConfig.params["nonce"] = randomString(16);
    requestConfig.params["sign"] = md5(
      requestConfig.params["timestamp"] +
        postData +
        requestConfig.params["nonce"] +
        (token ? payload.secret : "")
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    requestConfig!.headers!["Authorization"] = token;

    return requestConfig;
  },
  (error: AxiosError) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    if (
      response.data.code === "0000" ||
      response.data.code === 100 ||
      response.data.errcode == result_code
    ) {
      return response;
    } else if (response.data.code === 40003) {
      localStorage.removeItem("token");

      /**
       * 退出到对应的登录页面
       */
      let redirectUrl = localStorage.getItem("login_path");

      if (!redirectUrl) {
        redirectUrl = "/login";
      }

      router.push({ path: redirectUrl });

      return false;
    } else {
      ElMessage.error(response.data.msg);

      return response;
    }
  },
  (error: AxiosError) => {
    console.log("err" + error); // for debug
    ElMessage.error(error.message);
    return Promise.reject(error);
  }
);

export { service };

/**
 * 获取随机数
 * @param {*} len
 * @return
 */
function randomString(len: number) {
  const t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  let n = "";

  for (let i = 0; i < len; i++)
    n += t.charAt(Math.floor(Math.random() * t.length));
  return n;
}

/**
 * 获取负载数据
 */
function getPayload(token: string | null) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const Base64 = require("js-base64").Base64;

  if (token) {
    // 按照 . 进行分割
    const tokenArr = token.split(".", 3);

    const data = tokenArr[1];

    const payload = JSON.parse(Base64.decode(data));

    return payload;
  }
}
