import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/new_logo.png'


const _hoisted_1 = { class: "cpy-info" }
const _hoisted_2 = { class: "cpy-name" }
const _hoisted_3 = { style: {"color":"white","font-size":"16px","margin-bottom":"6px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_SwitchButton = _resolveComponent("SwitchButton")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_arrow_down = _resolveComponent("arrow-down")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_ButtonGroup = _resolveComponent("ButtonGroup")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_header, null, {
      default: _withCtx(() => [
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "logo" }, [
          _createElementVNode("img", { src: _imports_0 }),
          _createElementVNode("span", { style: {"white-space":"nowrap"} }, [
            _createElementVNode("h1", null, "收银台加单系统")
          ])
        ], -1)),
        _createVNode(_component_el_menu, {
          "default-active": _ctx.defatulPath,
          "background-color": "#09bb07",
          "text-color": "#FFFFFF",
          "active-text-color": "#409EFF",
          class: "el-menu-demo",
          mode: "horizontal"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routers, (item, index) => {
              return (_openBlock(), _createBlock(_component_el_menu_item, {
                onClick: ($event: any) => (_ctx.changeMenus(item, index, 1)),
                key: index,
                index: item.path
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1032, ["onClick", "index"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["default-active"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_icon, {
            size: 25,
            class: "icon",
            onClick: _ctx.loginOut
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SwitchButton)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_icon, {
            size: 25,
            class: "icon"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Avatar)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_dropdown, null, {
              dropdown: _withCtx(() => [
                _createVNode(_component_el_dropdown_menu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_dropdown_item, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showEditPwd = true))
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" 修改密码 ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, [
                  _createTextVNode(_toDisplayString(_ctx.nickName) + " ", 1),
                  _createVNode(_component_el_icon, { class: "el-icon--right" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_arrow_down)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.shopName), 1)
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.showEditPwd,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showEditPwd) = $event)),
      title: "修改密码",
      center: "",
      "close-on-click-modal": false
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_ButtonGroup, {
          submitText: "确定提交",
          submitImgIcon: 
          (() => {
            require('@/assets/icon/tijiaolvse.png');
          })()
        ,
          cancelText: "取消编辑",
          cancelImgIcon: 
          (() => {
            require('@/assets/icon/quxiao.png');
          })()
        ,
          onSubmitClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.editPwdDo())),
          onCancelClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.cancelEditPwdForm()))
        }, null, 8, ["submitImgIcon", "cancelImgIcon"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: _ctx.editPwdForm,
          "label-position": "top",
          class: "chain-form",
          rules: _ctx.accountRules,
          ref: "editPwdForm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "当前密码:",
              prop: "current_password"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.editPwdForm.current_password,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editPwdForm.current_password) = $event)),
                  type: "password",
                  placeholder: "请输入当前密码"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "新密码:",
              prop: "new_password"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.editPwdForm.new_password,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editPwdForm.new_password) = $event)),
                  type: "password",
                  placeholder: "请输入新密码"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "确认密码:",
              prop: "confirm_password"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.editPwdForm.confirm_password,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editPwdForm.confirm_password) = $event)),
                  type: "password",
                  placeholder: "请输入确认密码"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}