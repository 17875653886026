import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppSidebar = _resolveComponent("AppSidebar")!
  const _component_tags_view = _resolveComponent("tags-view")!
  const _component_AppMain = _resolveComponent("AppMain")!
  const _component_AppFooter = _resolveComponent("AppFooter")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_AppHeader, {
          onGetChindrenData: _ctx.getChindrenData,
          defaultMenus: _ctx.defaultMenus
        }, null, 8, ["onGetChindrenData", "defaultMenus"]),
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_AppSidebar, {
              ref: "AppSidebar",
              onGetHistoryPath: _ctx.getHistoryPath,
              defaultSonMenus: _ctx.defaultSonMenus
            }, null, 8, ["onGetHistoryPath", "defaultSonMenus"]),
            _createVNode(_component_el_container, { id: "main" }, {
              default: _withCtx(() => [
                _createVNode(_component_tags_view, {
                  historyPathData: _ctx.historyPathData,
                  onHistoryPathToMenus: _ctx.historyPathToMenus
                }, null, 8, ["historyPathData", "onHistoryPathToMenus"]),
                _createVNode(_component_AppMain),
                _createVNode(_component_AppFooter)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}