<template>
  <el-aside width="200px" style="height: calc(100% - 100px)">
    <el-scrollbar wrap-class="scrollbar-wrapper" style="background-color: #fff">
      <el-menu
        :default-active="defaultPath"
        class="el-menu-vertical-demo"
        router
      >
        <span style="color: #08b906; font-size: 12px">
          {{ menusData.title }}
        </span>

        <div v-for="(item, index) in menusData.children" :key="index">
          <div v-if="item.children">
            <el-menu-item-group :title="item.title">
              <div v-for="(sonItem, sonIndex) in item.children" :key="sonIndex">
                <div
                  v-if="
                    newMenusTipsPath &&
                    newMenusTipsPath.find((item) => sonItem.path == item)
                  "
                >
                  <img src="@/assets/icon/xin.png" class="img-icon" />
                </div>

                <router-link
                  :to="sonItem.path"
                  @click="saveHistoryPath(sonItem, menusData.path)"
                >
                  <el-menu-item
                    :index="sonItem.path"
                    v-show="sonItem.hidden == '1'"
                  >
                    {{ sonItem.title }}
                  </el-menu-item>
                </router-link>
              </div>
            </el-menu-item-group>
          </div>

          <router-link :to="item.path" v-else>
            <el-menu-item :index="item.path">
              <span>{{ item.title }}</span>
            </el-menu-item>
          </router-link>
        </div>
      </el-menu>
    </el-scrollbar>
  </el-aside>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ApSidebar",
  data() {
    return {
      /**
       * 默认菜单path
       */
      defaultPath: "",

      /**
       * 菜单数据
       */
      menusData: {} as any,

      /**
       * 历史path数据
       */
      historyPath: [] as any[],

      /**
       * 提醒菜单
       */
      newMenusTipsPath: [] as any[],
    };
  },
  methods: {
    /**
     * 获取当前默认path
     */
    getDefaultPath(item: any) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let that: any = this;

      if (item["children"]) {
        return that.getDefaultPath(item["children"][0]);
      } else {
        return item;
      }
    },

    /**
     * 记录历史路由地址
     */
    saveHistoryPath(sonItem: any, parantPath: any, isHight = "1") {
      if (isHight == "1") {
        this.defaultPath = sonItem.path;
        localStorage.setItem("default_path", this.defaultPath);
      }

      let historyPath = localStorage.getItem("history_path");

      /**
       * 历史路由标签内容
       */
      let params = {
        menus_name: sonItem.title,
        son_path: sonItem.path,
        parent_path: parantPath,
      };

      let savePath = true;

      if (historyPath) {
        this.historyPath = JSON.parse(historyPath);

        /**
         * 当前路由地址如果存在历史路由数组中则不加入
         */
        this.historyPath.forEach(function (item) {
          if (sonItem.path == item.son_path) {
            savePath = false;
          }
        });
      }

      /**
       * 存入历史路由数据
       */
      if (savePath && isHight == "1") {
        this.historyPath.push(params);

        localStorage.setItem("history_path", JSON.stringify(this.historyPath));
      }

      /**
       * 给父级组件传历史路径
       */
      this.$emit("getHistoryPath", this.historyPath);
    },

    /**
     * 子级菜单展示
     */
    childrenMenusData(menu: any, defaultPath = "", title = "", isHight = "2") {
      this.$nextTick(() => {
        /**
         * 左侧菜单展示
         */
        this.menusData = menu;

        /**
         * 获取默认菜单数据
         */
        let defaultItem = this.getDefaultPath(menu);

        if (defaultPath.length < 1) {
          defaultPath = defaultItem.path;
        }

        let parentPath = "/" + defaultPath.split("/")[1];

        if (title.length < 1) {
          title = defaultItem.title;
        }

        let cacheDefaultPath = localStorage.getItem("default_path");

        if (cacheDefaultPath && isHight == "2") {
          this.defaultPath = cacheDefaultPath;
        } else if (!cacheDefaultPath && isHight == "2") {
          this.defaultPath = defaultPath;

          isHight = "1";
        } else {
          this.defaultPath = defaultPath;
        }

        /**
         * 保存到历史菜单标签中
         */
        let saveHistoryPathParam = {
          title: title,
          path: defaultPath,
          parentPath: parentPath,
        };

        this.saveHistoryPath(saveHistoryPathParam, parentPath, isHight);

        /**
         * 加载当前菜单
         */
        this.$router.push({
          path: defaultPath,
          query: this.getUrlParams(defaultPath),
        });
      });
    },

    getUrlParams(url: any) {
      /**
       * 通过 ? 分割获取后面的参数字符串
       */
      let urlStr = url.split("?")[1];

      if (!urlStr) {
        return {};
      }

      /**
       * 创建空对象存储参数
       */
      let obj = [];

      /**
       * 再通过 & 将每一个参数单独分割出来
       */
      let paramsArr = urlStr.split("&");

      for (let i = 0, len = paramsArr.length; i < len; i++) {
        /**
         * 再通过 = 将每一个参数分割为 key:value 的形式
         */

        obj.push(paramsArr[i].split("="));
      }

      return Object.fromEntries(obj);
    },

    /**
     * 获取新菜单路由
     */
    getNewMenusTips() {
      /**
       * 读取token携带数据
       */
      const tokenData: any = localStorage.getItem("token_data");

      /**
       * 解析token数据
       */
      const tokenInfo = JSON.parse(tokenData);

      if (tokenInfo) {
        this.newMenusTipsPath = tokenInfo.new_menus_tips;
      }
    },
  },
  created() {
    this.getNewMenusTips();
  },
});
</script>

<style lang="less" scoped>
.el-aside {
  padding: 4px;
  overflow-y: scroll;
  width: var(--left-menu-max-width);
  background-color: var(--left-menu-bg-color);
  height: var(--tab-menu-height);
  position: relative;
  text-align: left;

  .el-menu {
    border-right: 0;

    .el-menu-item.is-active {
      background-color: #09bb07 !important;
      color: #fff !important;

      .router-link-active {
        color: #fff !important;
      }
    }
  }

  .img-icon {
    width: 23%;
    position: absolute;
    z-index: 1;
    right: 0;
  }
}

:deep(.el-menu-item-group .el-menu-item-group__title) {
  color: #09bb07;
  padding: 16px 0 !important;
}

:deep(.el-menu-item) {
  display: flex;
  justify-content: center;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
}

.el-menu-item:hover {
  background-color: #09bb07 !important;
  color: #ffffff !important;
}
</style>
