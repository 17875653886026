import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { AppLayout } from "@/layout";
import { CfaManagers } from "@/router/CfaManagers/index";
import { Statistics } from "@/router/Statistics/index";
import { Settings } from "@/router/Settings/index";

import { ChainManagement } from "@/router/ChainManagement/index";
import { GoodsManage } from "@/router/GoodsManagement/index";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Index",
    component: AppLayout,
    redirect: "/home",
    meta: { title: "首页", menus_type: 1 },
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/HomePageView.vue"),
        meta: { title: "数据概览", menus_type: 1 },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    meta: { title: "企微登录", menus_type: 3, hidden: true },
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/account-login",
    name: "AccountLogin",
    meta: { title: "账号登录", menus_type: 3, hidden: true },
    component: () => import("@/views/AccountLoginView.vue"),
  },
  CfaManagers, // 加单设置
  ChainManagement, // 连锁管理
  Statistics, // 数据报表
  GoodsManage, // 商品管理
  Settings, // 常用设置
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let notCheckPath = ["/login", "/account-login"];

  if (notCheckPath.includes(to.path)) {
    next();
  } else {
    let pathData = localStorage.getItem("path_data");

    /**
     * 判断是否拥有权限访问
     */
    if (pathData && pathData.includes(to.path)) {
      next();
    } else {
      /**
       * 退出到对应的登录页面
       */
      let redirectUrl = localStorage.getItem("login_path");

      if (!redirectUrl) {
        redirectUrl = "/login";
      }

      next(redirectUrl);
    }
  }
});

export default router;
