import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tags-view" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleClose = _resolveComponent("CircleClose")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.historyPathTagData, (pathItem) => {
        return (_openBlock(), _createElementBlock("div", {
          ref_for: true,
          ref: "tag",
          class: _normalizeClass(["tags-view-item", _ctx.isActive(pathItem) ? 'active' : '']),
          key: pathItem.son_path
        }, [
          _createElementVNode("span", {
            onClick: ($event: any) => (_ctx.goMenus(pathItem))
          }, _toDisplayString(pathItem.menus_name), 9, _hoisted_2),
          _createVNode(_component_el_icon, {
            size: "16",
            onClick: ($event: any) => (_ctx.closeSelectedTag(pathItem)),
            style: {"margin-left":"7px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CircleClose)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ], 2))
      }), 128))
    ])
  ]))
}