import { AppLayout } from "@/layout";

const Statistics = {
  path: "/statistics",
  name: "statistics",
  component: AppLayout,
  meta: {
    title: "数据报表",
    menus_type: 1,
  },
  children: [
    {
      path: "/statistics/index",
      name: "storeplanindex",
      meta: { title: "门店使用报表", menus_type: 1 },
      children: [
        {
          path: "/statistics/index/storeplan",
          name: "StorePlanView",
          component: () =>
            import("@/views/Statistics/storeplan/StorePlanView.vue"),
          meta: { title: "门店登录与方案状态", menus_type: 1 },
        },
      ],
    },
    {
      path: "/statistics/cfa",
      name: "chainplanindex",
      meta: { title: "加单数据报表", menus_type: 1 },
      children: [
        {
          path: "/statistics/cfa/relate",
          name: "RelateStatistics",
          component: () =>
            import("@/views/Statistics/relate/RelateStatistics.vue"),
          meta: { title: "需求关联加单数据报表", menus_type: 1 },
        },
        {
          path: "/statistics/cfa/course",
          name: "CourseStatistics",
          component: () =>
            import("@/views/Statistics/course/CourseStatistics.vue"),
          meta: { title: "单品促销加单数据报表", menus_type: 1 },
        },
        {
          path: "/statistics/cfa/season",
          name: "SeasonStatistics",
          component: () =>
            import("@/views/Statistics/season/SeasonStatisticsView.vue"),
          meta: { title: "应季推荐加单报表", menus_type: 1 },
        },
      ],
    },
  ],
};

export { Statistics };
