import { useAxios } from "@/utils/useAxios";

const request = useAxios();

export const testPost = async (data: any): Promise<Response> => {
  const res = await request.post({ url: "/user/login", data });

  return res && res.data;
};

export const testGet = async (params: any): Promise<Response> => {
  const res = await request.get({ url: "/user/login", params });

  return res && res.data;
};

/**
 * 获取图片验证码
 * @param params
 * @returns
 */
export const getLoginCaptcha = async (params: any): Promise<Response> => {
  const res = await request.get({ url: "/system/login/get-captcha", params });

  return res && res.data;
};

/**
 * 系统登录
 * @param data
 * @returns
 */
export const loginDo = async (data: any): Promise<Response> => {
  const res = await request.post({ url: "/system/login/login-do", data });

  return res && res.data;
};

/**
 * 登录-获取企微登录连接
 * @param params
 * @returns
 */
export const getLoginUrl = async (params: any): Promise<Response> => {
  const res = await request.get({ url: "/system/login/get-login-url", params });

  return res && res.data;
};

/**
 * 修改密码
 * @param data
 * @returns
 */
export const editUserPassword = async (data: any): Promise<Response> => {
  const res = await request.post({
    url: "/admin/chain-admin/admin-password-save",
    data,
  });

  return res && res.data;
};

/**
 * 快捷登录
 * @param data
 * @returns
 */
export const QuickLogin = async (data: any): Promise<Response> => {
  const res = await request.post({ url: "/system/login/quick-login", data });

  return res && res.data;
};
