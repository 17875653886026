import { AppLayout } from "@/layout";

const GoodsManage = {
  component: AppLayout,
  path: "/goodsManage",
  name: "goodsManage",
  meta: { title: "商品管理", menus_type: 2 },
  children: [
    {
      name: "goods",
      path: "/goodsManage/goods",
      meta: {
        title: "商品管理", // 商品管理
        menus_type: 2,
      },
      children: [
        {
          path: "/goodsManage/goods/lmkGoods",
          name: "lmkGoods",
          component: () => import("@/views/GoodsManage/LmkGoodsView.vue"),
          meta: { title: "标准商品库", menus_type: 2 },
        },
        {
          path: "/goodsManage/goods/chainGoodsRoom",
          name: "chainGoodsRoom",
          component: () => import("@/views/GoodsManage/ChainGoodsRoomView.vue"),
          meta: { title: "药店商品库", menus_type: 2 },
        },
        {
          path: "/goodsManage/goods/disease",
          name: "disease",
          component: () => import("@/views/GoodsManage/DiseaseView.vue"),
          meta: { title: "疾病分类管理", menus_type: 2 },
        },
        {
          path: "/goodsManage/goods/orderScheme",
          name: "orderScheme",
          component: () => import("@/views/GoodsManage/OrderSchemeView.vue"),
          meta: { title: "药店需求加单方案库", menus_type: 2 },
        },
        {
          path: "/goodsManage/goods/editOrderScheme",
          name: "editOrderScheme",
          component: () =>
            import("@/views/GoodsManage/EditOrderSchemeView.vue"),
          meta: { title: "编辑关联加单方案商品", menus_type: 2, hidden: true },
        },
        {
          path: "/goodsManage/goods/editRelateGoods",
          name: "editRelateGoods",
          component: () =>
            import("@/views/GoodsManage/EditRelateGoodsView.vue"),
          meta: { title: "编辑需求关联加单方案", menus_type: 2, hidden: true },
        },
      ],
    },
  ],
};

export { GoodsManage };
