/**
 * 退出程序清除缓存
 */
function loginOutDo() {
  localStorage.removeItem("token");
  localStorage.removeItem("token_data");
  localStorage.removeItem("menus_data");
  localStorage.removeItem("path_data");
  localStorage.removeItem("select_menus_index");
  localStorage.removeItem("select_parent_menu_path");
  localStorage.removeItem("history_path");
  localStorage.removeItem("default_path");
}

export default {
  loginOutDo,
};
