<template>
  <div class="button-group">
    <!-- 取消按钮 -->
    <el-button
      v-if="option.includes('cancel')"
      type="default"
      class="btn btn-cancel"
      :style="{ width: cancelButtonWidth }"
      :disabled="disabled || cancelDisabled"
      :loading="loading || cancelLoading"
      @click="handleClickCancelButton"
    >
      <template #default>
        <img
          v-if="cancelImgIcon"
          :src="cancelImgIcon"
          class="btn-icon btn-icon-cancel"
          alt="icon"
        />
        <span>{{ cancelText }}</span>
      </template>
    </el-button>

    <!-- 提交按钮 -->
    <el-button
      v-if="option.includes('submit')"
      type="default"
      class="btn btn-submit"
      :style="{ width: submitButtonWidth }"
      :disabled="disabled || submitDisabled"
      :loading="loading || submitLoading"
      @click="handleClickSubmitButton"
    >
      <template #default>
        <img
          v-if="submitImgIcon"
          :src="submitImgIcon"
          class="btn-icon btn-icon-submit"
          alt="icon"
        />
        <span>{{ submitText }}</span>
      </template>
    </el-button>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "ButtonGroup",
  emits: ["buttonClick", "submitClick", "cancelClick"],
  props: {
    option: {
      type: Array,
      default() {
        return ["cancel", "submit"];
      },
    },
    submitText: {
      type: String,
      default() {
        return "确定提交";
      },
    },
    cancelText: {
      type: String,
      default() {
        return "取消编辑";
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    cancelDisabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    submitDisabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    cancelLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    submitLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    submitImgIcon: {
      type: String,
      default() {
        return require("@/assets/icon/tijiaolvse.png");
      },
    },
    cancelImgIcon: {
      type: String,
      default() {
        return require("@/assets/icon/quxiao.png");
      },
    },
    submitButtonWidth: {
      type: String,
      default() {
        return "50%";
      },
    },
    cancelButtonWidth: {
      type: String,
      default() {
        return "50%";
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    /**
     * 点击取消按钮
     */
    handleClickCancelButton() {
      this.$emit("buttonClick", "cancel");
      this.$emit("cancelClick");
    },

    /**
     * 点击提交按钮
     */
    handleClickSubmitButton() {
      this.$emit("buttonClick", "submit");
      this.$emit("submitClick");
    },
  },
});
</script>
<style lang="scss" scoped>
.button-group {
  width: 100;
  display: flex;
  justify-content: space-around;
  .btn {
    //padding: 12px 40px;
    width: 50%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn :deep(span) {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 25px;
      width: 25px;
    }
    span {
      margin-left: 10px;
    }
  }
  .btn-cancel {
    border: 2px solid #797979;
    color: #797979;
  }
  .btn-submit {
    border: 2px solid #09bb07;
    color: hsl(119, 93%, 38%);
  }
}
</style>
