import { AppLayout } from "@/layout";

const CfaManagers = {
  path: "/cfaManagers",
  name: "cfaManagers",
  component: AppLayout,
  meta: {
    title: "加单设置",
    menus_type: 1,
  },
  children: [
    {
      name: "chainGoods",
      path: "/cfaManagers/chainGoods",
      meta: {
        title: "商品管理", // 商品管理
        menus_type: 1,
      },
      children: [
        {
          path: "/cfaManagers/chainGoods/index",
          name: "chainGoodsIndex",
          component: () =>
            import("@/views/CfaManagers/ChainGoods/ChainGoodsView.vue"),
          meta: {
            title: "药店商品库管理", // 药店商品库管理
            menus_type: 1,
          },
        },
      ],
    },
    {
      name: "plan",
      path: "/cfaManagers/plan",
      meta: {
        title: "方案管理", // 方案管理
        menus_type: 1,
      },
      children: [
        {
          path: "/cfaManagers/plan/chainRelated",
          name: "chainRelated",
          component: () =>
            import("@/views/CfaManagers/ChainRelated/ChainRelated.vue"),
          meta: { title: "需求关联加单方案管理", menus_type: 1 },
        },
        {
          path: "/cfaManagers/plan/editRelated",
          name: "editRelated",
          component: () =>
            import("@/views/CfaManagers/ChainRelated/EditRelated.vue"),
          meta: {
            title: "新增/编辑需求管理加单方案",
            hidden: true,
            menus_type: 1,
          },
        },
        {
          path: "/cfaManagers/plan/treatment",
          name: "treatment",
          component: () =>
            import("@/views/CfaManagers/Treatment/TreatmentView.vue"),
          meta: { title: "单品促销加单方案管理", menus_type: 1 },
        },
        {
          path: "/cfaManagers/plan/editReatment",
          name: "editReatment",
          component: () =>
            import("@/views/CfaManagers/Treatment/EditTreatment.vue"),
          meta: {
            title: "新增/编辑单品促销加单方案",
            hidden: true,
            menus_type: 1,
          },
        },
        {
          path: "/cfaManagers/plan/seasonalPlan",
          name: "seasonalPlan",
          component: () =>
            import("@/views/CfaManagers/SeasonalPlan/SeasonalView.vue"),
          meta: { title: "应季推荐加单方案库", menus_type: 1 },
        },
        {
          path: "/cfaManagers/plan/editSeasonal",
          name: "editSeasonal",
          component: () =>
            import("@/views/CfaManagers/SeasonalPlan/EditSeasonalView.vue"),
          meta: {
            title: " 新增/编辑应季推荐加单方案",
            hidden: true,
            menus_type: 1,
          },
        },
      ],
    },
  ],
};

export { CfaManagers };
