<template>
  <el-header>
    <div class="logo">
      <img src="@/assets/new_logo.png" />
      <span style="white-space: nowrap"><h1>收银台加单系统</h1></span>
    </div>

    <el-menu
      :default-active="defatulPath"
      background-color="#09bb07"
      text-color="#FFFFFF"
      active-text-color="#409EFF"
      class="el-menu-demo"
      mode="horizontal"
    >
      <el-menu-item
        @click="changeMenus(item, index, 1)"
        v-for="(item, index) in routers"
        :key="index"
        :index="item.path"
      >
        <span>{{ item.title }}</span>
      </el-menu-item>
    </el-menu>

    <div class="cpy-info">
      <el-icon :size="25" class="icon" @click="loginOut">
        <SwitchButton />
      </el-icon>

      <el-icon :size="25" class="icon">
        <Avatar />
      </el-icon>

      <div class="cpy-name">
        <el-dropdown>
          <span style="color: white; font-size: 16px; margin-bottom: 6px">
            {{ nickName }}
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="showEditPwd = true">
                修改密码
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <span>{{ shopName }}</span>
      </div>
    </div>
  </el-header>

  <el-dialog
    v-model="showEditPwd"
    title="修改密码"
    center
    :close-on-click-modal="false"
  >
    <el-form
      :model="editPwdForm"
      label-position="top"
      class="chain-form"
      :rules="accountRules"
      ref="editPwdForm"
    >
      <el-form-item label="当前密码:" prop="current_password">
        <el-input
          v-model="editPwdForm.current_password"
          type="password"
          placeholder="请输入当前密码"
        />
      </el-form-item>

      <el-form-item label="新密码:" prop="new_password">
        <el-input
          v-model="editPwdForm.new_password"
          type="password"
          placeholder="请输入新密码"
        />
      </el-form-item>

      <el-form-item label="确认密码:" prop="confirm_password">
        <el-input
          v-model="editPwdForm.confirm_password"
          type="password"
          placeholder="请输入确认密码"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <ButtonGroup
        submitText="确定提交"
        :submitImgIcon="
          (() => {
            require('@/assets/icon/tijiaolvse.png');
          })()
        "
        cancelText="取消编辑"
        :cancelImgIcon="
          (() => {
            require('@/assets/icon/quxiao.png');
          })()
        "
        @submitClick="editPwdDo()"
        @cancelClick="cancelEditPwdForm()"
      />
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import router from "@/router";
import ButtonGroup from "@/components/ButtonGroup/ButtonGroup.vue";
import { ElMessage } from "element-plus";
import { editUserPassword } from "@/api/login";
import helper from "@/utils/common";
import store from "@/store";

export default defineComponent({
  name: "AppHeader",
  components: {
    ButtonGroup,
  },
  emits: {
    getChindrenData: null,
  },
  props: {
    defaultMenus: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      /**
       * 用户昵称
       */
      nickName: "",

      /**
       * 连锁名称
       */
      shopName: "",

      /**
       * 默认跳转路由
       */
      defatulPath: "",

      /**
       * 是否展示修改密码窗口
       */
      showEditPwd: false,

      /**
       * 修改密码form表单
       */
      editPwdForm: {
        /**
         * 当前密码
         */
        current_password: "",

        /**
         * 新密码
         */
        new_password: "",

        /**
         * 确认密码
         */
        confirm_password: "",
      },

      accountRules: {
        current_password: [
          { required: true, message: "请输入当前密码", trigger: "blur" },
        ],
        new_password: [
          { required: true, message: "请输入账号密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "6-16个字符，建议数字加字母6个字符以上",
            trigger: "blur",
          },
        ],

        confirm_password: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "6-16个字符，建议数字加字母6个字符以上",
            trigger: "blur",
          },
        ],
      }, // 效验账号设置信息内容

      editPwdFormLoading: false,
    };
  },
  watch: {
    defaultMenus(val: any) {
      this.defatulPath = val;
    },
  },
  created() {
    const token: any = localStorage.getItem("token");

    if (token) {
      /**
       * 按照 . 进行分割
       */

      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const Base64 = require("js-base64").Base64;

      const tokenArr = token.split(".", 3);
      const data = tokenArr[1];
      const tokenInfo = JSON.parse(Base64.decode(data));

      /**
       * 设置连锁对接ERP状态
       */
      store.commit("setShopDockErpStatus", tokenInfo.erp_no);

      /**
       * 将token携带信息存储，方便调用
       */

      localStorage.setItem("token_data", JSON.stringify(tokenInfo));

      this.nickName = tokenInfo.nickname;
      this.shopName = tokenInfo.shop_name;
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    routers() {
      let routers = localStorage.getItem("menus_data");

      /**
       * 解析菜单数据
       */
      if (routers) {
        routers = JSON.parse(routers);
      }

      return routers;
    },
  },
  methods: {
    /**
     * 选择子级菜单
     */
    changeMenus(items: any, index: any, isClick = 0) {
      localStorage.setItem("select_menus_index", index);
      localStorage.setItem("select_parent_menu_path", items.path);

      this.defatulPath = items.path;

      this.$emit("getChindrenData", items, isClick);
    },

    /**
     * 退出后清空缓存信息
     */
    loginOut() {
      /**
       * 退出到对应的登录页面
       */
      let redirectUrl = localStorage.getItem("login_path");

      if (!redirectUrl) {
        redirectUrl = "/login";
      }

      /**
       * 清除缓存信息
       */
      helper.loginOutDo();

      router.push({ path: redirectUrl });
    },

    /**
     * 修改密码
     */
    editPwdDo() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let that: any = this;

      if (that.editPwdForm.new_password != that.editPwdForm.confirm_password) {
        ElMessage({
          message: "新密码与确认密码不一致，请确认后输入！",
          type: "error",
        });

        return false;
      }

      that.$refs.editPwdForm.validate((valid: any) => {
        if (valid) {
          editUserPassword(that.editPwdForm).then((res: any) => {
            if (res.code == 100) {
              ElMessage({
                message: res.msg,
                type: "success",
              });
              this.showEditPwd = false;
            }
          });
        } else {
          return false;
        }
      });
    },

    /**
     * 取消提交清空验证提示
     */
    cancelEditPwdForm() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let that: any = this;

      this.showEditPwd = false;
      that.$refs.editPwdForm.clearValidate();
    },
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let that: any = this;

    let menusIndex: any = localStorage.getItem("select_menus_index");
    let parentPath: any = localStorage.getItem("select_parent_menu_path");

    if (parentPath) {
      that.defatulPath = parentPath;
    }

    if (menusIndex == null || menusIndex == "") {
      menusIndex = 0;
    }

    this.changeMenus(that.routers[menusIndex], menusIndex);
  },
});
</script>

<style lang="less" scoped>
.el-header {
  background-color: var(--top-header-bg-color);
  color: var(--top-header-text-color);
  line-height: var(--top-tool-height);
  height: var(--top-tool-height);
  display: flex;
  justify-content: space-between;

  .nav {
    flex: 1;
    padding-left: 4%;
  }

  .logo {
    display: flex;
    align-items: center;
    padding: 5px;
    img {
      height: 100%;
    }
    span {
      margin-left: 10px;
      color: #ffffff;
      font-weight: 900;
    }
  }
  .cpy-info {
    display: flex;
    align-items: center;

    .icon {
      margin: 0px 10px;
      cursor: pointer;
    }

    .cpy-name {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      line-height: 1rem;
    }
  }

  :deep(.el-menu--horizontal) {
    width: 60%;
    border-bottom: none;
  }
  :deep(.el-menu--horizontal > .el-menu-item) {
    font-size: 16px;
    border: none;
    font-weight: 700;
  }
  :deep(.el-menu--horizontal > .el-menu-item.is-active) {
    color: #ffffff !important;
    background-color: #13761d73 !important;
    border-bottom-color: #13761d73 !important;
  }
}
</style>
