import { AppLayout } from "@/layout";

const Settings = {
  path: "/settings",
  name: "Settings",
  component: AppLayout,
  // redirect: "/settings/organization/storeArea",
  meta: {
    title: "账号等设置",
    menus_type: 3,
  },
  children: [
    {
      name: "organization",
      path: "/settings/organization",
      meta: {
        title: "组织架构设置", // 组织架构设置
        menus_type: 1,
      },
      children: [
        {
          path: "/settings/organization/storeArea",
          name: "storeArea",
          component: () =>
            import("@/views/Setting/OrganizeManagement/StoreAreaView.vue"),
          meta: { title: "区域门店管理", menus_type: 1 },
        },
        {
          path: "/settings/organization/account",
          name: "account",
          component: () =>
            import("@/views/Setting/OrganizeManagement/AccountView.vue"),
          meta: { title: "员工账号管理", menus_type: 1 },
        },
        {
          path: "/settings/organization/backAccount",
          name: "backAccount",
          component: () =>
            import("@/views/Setting/OrganizeManagement/BackAccountView.vue"),
          meta: { title: "后台账号管理", menus_type: 1 },
        },
        {
          path: "/settings/organization/activity",
          name: "activity",
          component: () =>
            import("@/views/Setting/OrganizeManagement/ActivityView.vue"),
          meta: { title: "活动分组管理", menus_type: 1 },
        },
      ],
    },
    {
      path: "/settings/systemNotice",
      // redirect: "/settings/systemNotice/notice",
      name: "systemNotice",
      meta: { title: "系统更新发版管理", menus_type: 2 },
      children: [
        {
          path: "/settings/systemNotice/notice",
          name: "notice",
          component: () =>
            import("@/views/Setting/System/notice/NoticeView.vue"),
          meta: { title: "系统更新发版管理", menus_type: 2 },
        },
        {
          path: "/settings/systemNotice/EditAdminNotice",
          name: "EditAdminNotice",
          component: () =>
            import("@/views/Setting/System/notice/EditAdminNoticeView.vue"),
          meta: {
            title: "新增/编辑连锁后台公告页面",
            hidden: true,
            menus_type: 2,
          },
        },
        {
          path: "/settings/systemNotice/EditClientNotice",
          name: "EditClientNotice",
          component: () =>
            import("@/views/Setting/System/notice/EditClientNoticeView.vue"),
          meta: {
            title: "新增/编辑PC端后台公告页面",
            hidden: true,
            menus_type: 2,
          },
        },
        {
          path: "/settings/systemNotice/EditDeviceNotice",
          name: "EditDeviceNotice",
          component: () =>
            import("@/views/Setting/System/notice/EditDeviceNoticeView.vue"),
          meta: {
            title: "新增/编辑平板设备公告页面",
            hidden: true,
            menus_type: 2,
          },
        },
        // {
        //   path: "/settings/systemNotice/notice",
        //   name: "notice",
        //   component: () => import("@/views/Setting/System/NoticeView.vue"),
        //   meta: { title: "系统更新公告列表", menus_type: 2 },
        // },
        // {
        //   path: "/settings/systemNotice/EditNotice",
        //   name: "EditNotice",
        //   component: () => import("@/views/Setting/System/EditNoticeView.vue"),
        //   meta: { title: "新增/编辑页面", hidden: true, menus_type: 2 },
        // },
        {
          path: "/settings/systemNotice/adertConfig",
          name: "adertConfig",
          component: () => import("@/views/Setting/System/AdertConfigView.vue"),
          meta: { title: "广告配置页面", menus_type: 2 },
        },
        {
          path: "/settings/systemNotice/editAdertConfig",
          name: "editAdertConfig",
          component: () =>
            import("@/views/Setting/System/EditAdertConfigView.vue"),
          meta: { title: "新增/编辑广告", menus_type: 2, hidden: true },
        },
      ],
    },
    {
      name: "system",
      path: "/settings/system",
      meta: {
        title: "系统管理", // 系统管理
        menus_type: 1,
      },
      children: [
        {
          path: "/settings/system/equipmentView",
          name: "equipmentView",
          component: () => import("@/views/Setting/System/EquipmentView.vue"),
          meta: { title: "设备管理", menus_type: 1 },
        },
        {
          path: "/settings/system/advertView",
          name: "advertView",
          component: () =>
            import("@/views/Setting/System/advertManagement/AdvertView.vue"),
          meta: { title: "平板待机广告管理", menus_type: 1 },
        },
        {
          path: "/settings/system/editAdvertView",
          name: "editAdvertView",
          component: () =>
            import(
              "@/views/Setting/System/advertManagement/EditAdvertView.vue"
            ),
          meta: { title: "添加广告", hidden: true, menus_type: 1 },
        },
        {
          path: "/settings/system/storePoster",
          name: "storePoster",
          component: () => import("@/views/Setting/System/StorePosterView.vue"),
          meta: { title: "支付后广告设置", menus_type: 1 },
        },
        {
          path: "/settings/system/editPoster",
          name: "editPoster",
          component: () => import("@/views/Setting/System/EditPoster.vue"),
          meta: { title: "编辑营销方案", hidden: true, menus_type: 1 },
        },
        {
          path: "/settings/system/control",
          name: "control",
          component: () => import("@/views/Setting/System/SettingView.vue"),
          meta: { title: "使用监控", menus_type: 1 },
        },
        {
          path: "/settings/system/useStatus",
          name: "useStatus",
          component: () => import("@/views/Setting/System/UseStatusView.vue"),
          meta: { title: "登录使用状态", menus_type: 1 },
        },
      ],
    },
    {
      path: "/settings/keywordReply",
      name: "keywordReply",
      meta: { title: "关键字回复", menus_type: 2 },
      children: [
        {
          path: "/settings/systemNotice/keyword",
          name: "keyword",
          component: () => import("@/views/Setting/Keyword/KeywordView.vue"),
          meta: { title: "关键字回复设置", menus_type: 2 },
        },
        {
          path: "/settings/systemNotice/editKeyword",
          name: "editKeyword",
          component: () =>
            import("@/views/Setting/Keyword/EditKeywordView.vue"),
          meta: { title: "新增/编辑页面", hidden: true, menus_type: 2 },
        },
      ],
    },
    {
      path: "/material",
      // redirect: "/material/planmaterial",
      name: "material",
      meta: { title: "素材管理", menus_type: 2 },
      children: [
        {
          path: "/material/planMaterial",
          name: "planMaterial",
          component: () =>
            import("@/views/Setting/Material/PlanMaterialView.vue"),
          meta: { title: "方案素材管理", menus_type: 2 },
        },
        {
          path: "/material/planmaterial/editRelatePlanmaterial",
          name: "editRelatePlanmaterial",
          component: () =>
            import("@/views/Setting/Material/EditRelatePlanMaterialView.vue"),
          meta: {
            title: "新增/编辑关联加单方案素材",
            hidden: true,
            menus_type: 2,
          },
        },
        {
          path: "/material/planmaterial/editCoursePlanmaterial",
          name: "editCoursePlanmaterial",
          component: () =>
            import("@/views/Setting/Material/EditCoursePlanMaterialView.vue"),
          meta: {
            title: "新增/编辑疗程用药加单方案素材",
            hidden: true,
            menus_type: 2,
          },
        },
        {
          path: "/material/planmaterial/editSeasonalPlanMaterial",
          name: "editSeasonalPlanMaterial",
          component: () =>
            import("@/views/Setting/Material/EditSeasonalPlanMaterialView.vue"),
          meta: {
            title: "新增/编辑应季推荐加单方案素材",
            hidden: true,
            menus_type: 2,
          },
        },
        // {
        //   path: "posterMaterial",
        //   name: "posterMaterial",
        //   component: () =>
        //     import("@/views/Setting/Material/PosterMaterialView.vue"),
        //   meta: { title: "海报素材管理", menus_type: 2 },
        // },
      ],
    },
    {
      path: "/accountmanage",
      name: "accountmanage",
      meta: { title: "账号管理", menus_type: 2 },
      children: [
        {
          path: "/settings/cgbackAccount",
          name: "cgbackAccount",
          component: () => import("@/views/Setting/CgBackAccountView.vue"),
          meta: { title: "超管后台账号管理", menus_type: 2 },
        },
      ],
    },
  ],
};

export { Settings };
