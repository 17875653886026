import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/icon/xin.png'


const _hoisted_1 = { style: {"color":"#08b906","font-size":"12px"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_menu_item_group = _resolveComponent("el-menu-item-group")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_aside = _resolveComponent("el-aside")!

  return (_openBlock(), _createBlock(_component_el_aside, {
    width: "200px",
    style: {"height":"calc(100% - 100px)"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_scrollbar, {
        "wrap-class": "scrollbar-wrapper",
        style: {"background-color":"#fff"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_menu, {
            "default-active": _ctx.defaultPath,
            class: "el-menu-vertical-demo",
            router: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.menusData.title), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menusData.children, (item, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, [
                  (item.children)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_el_menu_item_group, {
                          title: item.title
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (sonItem, sonIndex) => {
                              return (_openBlock(), _createElementBlock("div", { key: sonIndex }, [
                                (
                    _ctx.newMenusTipsPath &&
                    _ctx.newMenusTipsPath.find((item) => sonItem.path == item)
                  )
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
                                      _createElementVNode("img", {
                                        src: _imports_0,
                                        class: "img-icon"
                                      }, null, -1)
                                    ])))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_router_link, {
                                  to: sonItem.path,
                                  onClick: ($event: any) => (_ctx.saveHistoryPath(sonItem, _ctx.menusData.path))
                                }, {
                                  default: _withCtx(() => [
                                    _withDirectives(_createVNode(_component_el_menu_item, {
                                      index: sonItem.path
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(sonItem.title), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["index"]), [
                                      [_vShow, sonItem.hidden == '1']
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["to", "onClick"])
                              ]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["title"])
                      ]))
                    : (_openBlock(), _createBlock(_component_router_link, {
                        key: 1,
                        to: item.path
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_menu_item, {
                            index: item.path
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(item.title), 1)
                            ]),
                            _: 2
                          }, 1032, ["index"])
                        ]),
                        _: 2
                      }, 1032, ["to"]))
                ]))
              }), 128))
            ]),
            _: 1
          }, 8, ["default-active"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}